// i18next-extract-mark-ns-start products-ringpak-dynamics

import { RINGPAK_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductRingpakPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							RINGPAK uses axis-symmetric assumptions to simulate
							twist, and axial and radial motions of separate rings on
							the piston assembly. In addition, effects of surrounding
							gas pressure, boundary lubrication, hydrodynamic
							lubrication, system friction and inertia are also
							considered in force and momentum balances.
						</p>
						<p>
							Sealing calculations can be performed that are based on
							detailed gas-flow analysis through the ring pack. From
							these studies, blow-by and blow-back values can be
							predicted. Using this methodology, RINGPAK forms an
							interconnected system of gas volumes. The gas flow
							between these volumes is controlled by the ring motion
							or by the conformability of the rings to the liner body
							during deformation.&nbsp;
						</p>
						<p>
							RINGPAK includes two flow models, one based on
							isentropic conditions and the other on compressible
							isothermal flow. Each flow model can be selected
							explicitly or RINGPAK can calculate the best solution
							based on flow parameters.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>
								Axis-symmetric 2D treatment, including 3D features
								for bore distortion, ring conformability, crown
								shape and crown thermal distortion
							</li>
							<li>Mass-conserving hydrodynamic lubrication model</li>
							<li>Boundary lubrication model</li>
							<li>
								Channel flow and orifice flow inter-ring gas
								dynamics
							</li>
							<li>Ring-liner conformability</li>
							<li>Different oil supply modes</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{RINGPAK_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductRingpakPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-ringpak-dynamics", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/ringpak-hero.jpg" }) {
		...imageBreaker
	}
}
`;

